.home-container {
    background-color: #f5f5f5;
    padding: 20px;
    text-align: center;
}

.hero {
    background-image: url('../assets/pexels-tima-miroshnichenko-6011598.jpg');
    background-size: cover;
    color: white;
    padding: 50px 20px;
}

.services {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin-top: 40px;
}

.service {
    flex: 1;
    padding: 20px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

footer.contact {
    background-color: #282c34;
    color: white;
    padding: 20px;
    position: relative;
    bottom: 0;
    width: 100%;
}

/* Mobile styles */
@media (max-width: 768px) {
    .services {
        flex-direction: column;
        gap: 20px;
    }

    .hero {
        padding: 30px 10px;
    }
}

@media (max-width: 480px) {
    .hero {
        padding: 20px 5px;
    }

    .services {
        gap: 10px;
    }
}
