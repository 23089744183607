/* CSS Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
}

header {
  background-color: #282c34;
  padding: 10px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 1.5em;
}

nav ul {
  list-style: none;
  display: flex;
  gap: 10px;
}

nav ul li a {
  color: white;
  text-decoration: none;
}

nav ul li a:hover {
  text-decoration: underline;
}

h1 {
  color: #61dafb;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 20px auto;
}

/* Media Queries */

/* Styles for tablets */
@media (max-width: 768px) {
  header {
      flex-direction: column;
      padding: 20px;
  }

  .logo {
      font-size: 1.2em;
  }

  nav ul {
      flex-direction: column;
      gap: 5px;
  }
}

/* Styles for mobile devices */
@media (max-width: 480px) {
  header {
      flex-direction: row;
      padding: 15px;
  }

  .logo {
      font-size: 1em;
  }

  nav ul {
      flex-direction: column;
      gap: 5px;
  }

  h1 {
      font-size: 1.5em;
  }

  img {
      margin: 10px auto;
  }
}



/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
