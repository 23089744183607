/* Contact.css */
.contact-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .contact-container h1 {
    text-align: center;
    font-size: xx-large;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    text-align: left;
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  textarea[name="message"] {
    height: 200px;
  }

  button {
    width: 100%;
    padding: 10px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  text-align: center;
}

.popup-content .checkmark-circle {
  width: 80px;
  height: 80px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.popup-content .checkmark-circle .background {
  width: 100%;
  height: 100%;
  background-color: #4BB543;
  border-radius: 50%;
  position: absolute;
}

.popup-content .checkmark-circle .checkmark {
  height: 35px;
  width: 20px;
  border: 5px solid white;
  border-top: none;
  border-left: none;
  position: absolute;
  top: 22px;
  left: 26px;
  transform: rotate(45deg);
}

.popup-content h2 {
  margin-top: 20px;
  color: #007BFF;
}

.popup-content button {
  background-color: #007BFF;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.popup-content button:hover {
  background-color: #0056b3;
}

/* Mobile styles */
@media (max-width: 768px) {
  .contact-container {
      padding: 15px;
  }

  .contact-container h1 {
      font-size: large;
  }
}

@media (max-width: 480px) {
  .contact-container {
      padding: 10px;
  }

  .contact-container h1 {
      font-size: medium;
  }
}
