header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    background-color: azure; 
    color: #282c34;
    position: relative;
  }
  
  .logo {
    font-size: 1.5em;
    margin-right: auto;
    /* position: absolute;
    top: 10px;
    left: 10px; */
  }
  
  nav ul {
    list-style: none;
    display: flex;
    gap: 20px;
  }
  
  /* nav ul li {
    display: inline;
  } */
  
  nav ul li a {
    color: #282c34;
    text-decoration: none;
  }
  
  nav ul li a:hover {
    text-decoration: underline;
  }

  .hamburger {
    display: none;
    font-size: 1.5em;
    cursor: pointer;
}

/* Tablet and mobile styles */
@media (max-width: 768px) {
  nav {
      display: none;
  }

  .nav.open {
      display: flex;
      flex-direction: column;
      gap: 5px;
      position: absolute;
      top: 50px;
      right: 20px;
      background-color: azure;
      padding: 10px;
      border-radius: 5px;
  }

  .hamburger {
      display: block;
  }
}
